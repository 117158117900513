// BASIC
export const PRIMARY = '#1779ba';
export const SECONDARY = '#767676';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

// ACTIONS
export const SUCCESS = '#3adb76';
export const WARNING = '#ffae00';
export const ALERT = '#cc4b37';
export const INFO = '#24B4C7';

// GRAYSCALE
export const GRAY_LIGHT = '#e6e6e6';
export const GRAY_MEDIUM = '#cacaca';
export const GRAY_DARK = '#8a8a8a';

// BRANDS
export const BRAND_FACEBOOK = '#3b5998';
export const BRAND_TWITTER = '#1da1f2';
export const BRAND_INSTAGRAM = '#c13584';

// THEME
// Para ver paleta de colores ir a https://www.w3schools.com/w3css/w3css_color_generator.asp
// luego ingresar el valor de THEME (por ejemplo: #07beb8) y presionar el botón Create Theme
export const THEME_L5 = '#edf9ff';
export const THEME_L4 = '#c2eaff';
export const THEME_L3 = '#85d4ff';
export const THEME_L2 = '#47bfff';
export const THEME_L1 = '#0aa9ff';
export const THEME = '#0082ca';
export const THEME_D1 = '#0077b8';
export const THEME_D2 = '#006aa3';
export const THEME_D3 = '#005d8f';
export const THEME_D4 = '#00507a';
export const THEME_D5 = '#004266';