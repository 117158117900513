import React, { useState, useEffect } from 'react';
import { Api } from 'services';
import Select from 'react-select';
import { withRouter } from "react-router-dom";
import './productos.css';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
// import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const VerProducto = (props) => {
    const [producto, setProducto] = useState({
        ...props.producto,
        descripcion: (props.producto && props.producto.descripcion) ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(props.producto.descripcion)
            )
        ) : EditorState.createEmpty(),
        especificaciones: (props.producto && props.producto.especificaciones) ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(props.producto.especificaciones)
            )
        ) : EditorState.createEmpty(),
        destacado: ( props.producto && props.producto.destacado == '1' ) ? true : false,
        activo: ( props.producto && props.producto.activo == '1' ) ? true : false,
        precio: ( props.producto && props.producto.precio ) ? parseInt( props.producto.precio) : ''
    });
    const [selectOptions, setSelectOptions] = useState([]);
    useEffect(() => {
        if (props.configProductos && props.configProductos.jerarquias && props.configProductos.jerarquias.length > 0) {
            let options = [];
            for (let i in props.configProductos.jerarquias) {
                options.push({ value: props.configProductos.jerarquias[i].id, label: props.configProductos.jerarquias[i].nombre });
            }
            setSelectOptions(options);
        }
        if (producto && producto.jerarquias && producto.jerarquias.length > 0) {
            let jerIds = [];
            for (let i in producto.jerarquias) {
                jerIds.push({ value: producto.jerarquias[i].id, label: producto.jerarquias[i].nombre });
            }
            setProducto({ ...producto, jerarquiasIds: jerIds });
        }
    }, []);
    return (
        <div id="ver-producto">
            <div className="w3-padding-top bs-bbox">
                <form className="w3-margin-bottom w3-padding">
                    <div className="w3-row">
                        <div className="w3-col l4 w3-padding">
                            <p>Estás viendo los datos de un producto</p>
                        </div>
                        <div className="w3-col l8 w3-padding bs-bbox">
                            <div className="w3-white bs-bbox w3-padding-large">
                                <div className="w3-block w3-border w3-padding w3-round-small">
                                    <div className="w3-row w3-margin-top">
                                        {producto.multimedia.map(multime => (<div key={multime.id + multime.nombre} className="w3-col bs-bbox" style={{ width: '128px', padding: '4px' }}>
                                            <div className="w3-border w3-round" style={{
                                                width: '100%',
                                                paddingTop: '100%',
                                                backgroundColor: '#F4F6F8',
                                                position: 'relative',
                                                backgroundPosition: 'center',
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: "url('" + Api.route(multime.url) + "')"
                                            }}>
                                            </div>
                                        </div>))}
                                    </div>
                                </div>
                                <br />
                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    name="orden"
                                    type="number"
                                    label="Orden"
                                    value={producto.orden}
                                    disabled={true}
								/>
								<br />
                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    name="nombre"
                                    type="text"
                                    label="Nombre"
                                    value={producto.nombre}
                                    disabled={true}
								/>
								<br />
								<TextField fullWidth size="small" margin="normal" variant="outlined"
                                    name="codigo"
                                    type="text"
                                    label="Código"
                                    value={producto.codigo}
                                    disabled={true}
								/>
								<br />

                                <span>Descripción</span>
                                <Editor
                                    toolbarHidden
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"                                    
                                    editorState={producto.descripcion}
                                /><br />

                                {/* <span>Especificaciones</span>
                                <Editor
                                    toolbarHidden
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    editorState={producto.especificaciones}
                                /><br /><br /> */}

                                {/* <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    multiline
                                    rows={4}
                                    name="descripcion"
                                    type="text"
                                    label="Descripción"
                                    value={producto.descripcion}
                                    disabled={true} /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    multiline
                                    rows={5}
                                    name="especificaciones"
                                    type="text"
                                    label="Especificaciones"
                                    value={producto.especificaciones}
                                    disabled={true} /><br /> */}

                                <FormControlLabel disabled={true}
                                    control={
                                        <Checkbox
                                            checked={producto.destacado}
                                            name="destacado"
                                            color="primary"
                                        />
                                    }
                                    label="Destacado"
                                /><br />

                                <div className="w3-row">
                                    <div className="w3-col l6 m6 s12 bs-bbox" style={{paddingRight: '4px'}}>
                                        <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                            name="precio"
                                            type="number"
                                            label="Precio"
                                            value={producto.precio}
                                            disabled={true} />
                                    </div>
                                    <div className="w3-col l6 m6 s12">
                                        <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                            name="cantidad"
                                            type="number"
                                            label="Cantidad"
                                            value={producto.cantidad}
                                            disabled={true} />
                                    </div>
                                </div>

                                <FormControlLabel disabled={true}
                                    control={
                                        <Checkbox
                                            checked={producto.activo}
                                            name="activo"
                                            color="primary"
                                        />
                                    }
                                    label="Activo"
                                /><br />

                                {selectOptions.length > 0 ? <>
                                    <label>Categoría/s</label>
                                    <Select
                                        isMulti
                                        name="jerarquiasIds"
                                        value={producto.jerarquiasIds}
                                        options={selectOptions}
                                        placeholder="Categoría"
                                        isDisabled={true} />
                                </> : null}
                            </div>
                        </div>
                    </div>
                </form>
                <Divider />
                <div className="w3-row w3-white">
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                            type="button"
                            onClick={() => props.cancelar()}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(VerProducto);